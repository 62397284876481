<template>
  <div class="card content__main" @click="goToProject">
    <h1>{{ project.name.charAt(0) }}</h1>
    <h2>{{ project.name }}</h2>
    <span></span>
    <div class="card__info">
      <div class="card__info-line digital">Digital: {{ project.digitalTotal }}</div>
      <div class="card__info-line physical">Physical: {{ project.inc }}</div>
      <div class="card__info-line bad">Bad: {{ project.badTotal }}</div>
    </div>
    <span></span>
    <div class="card__info-line-total">Total: {{ project.totalCards }}</div>
  </div>
</template>
  
<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToProject() {
      this.$router.push({
        name: "MainPanel",
        params: { tableName: this.project.tableName },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  transition: all 0.3s;
  &__info {
    &-line {
      text-align: left;
      background: transparent;
      &.digital {
        color: #79a1ff;
      }
      &.physical {
        color: #e9c2e9;
      }
      &.bad {
        color: #ad3d3d;
      }
      & + .card__info-line {
        margin-top: 10px;
      }
    }
    &-line-total {
      font-weight: 700;
    }
  }
  h1 {
    margin: 0;
    color: rgba(255, 255, 255, 0.15);
    font-size: 6em;
  }
  h2 {
    margin-top: -35px;
    font-size: 27px;
  }
  span {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin: 15px 0;
    display: block;
  }
  &:hover {
    box-shadow: 0 7px 50px 0px #000000aa;
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.5);
    h1 {
      color: rgba(255, 255, 255, 0.25);
    }
  }
  min-width: 185px;
  max-width: 185px;
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s;
  &:hover {
    border-color: rgba(255, 255, 255, 0.85);
  }
  h2 {
    text-transform: uppercase;
  }
}
</style>
