<template>
    <section class="login content__main">
      <div class="login__inner">
        <div class="login__title">Авторизация</div>
        <hr />
        <form @submit.prevent="submitLogin">
          <input type="text" v-model="login" placeholder="Login" required />
          <input type="password" v-model="password" placeholder="Password" required />
  
          <button type="submit" class="login-btn">
            Войти
            <i class="bx bx-user mr-1"></i>
          </button>
        </form>
  
        <img src="images/logo-icon.png" alt="Logo" />
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        login: '',
        password: ''
      };
    },
    methods: {
      async submitLogin() {
        try {
          // Отправляем данные для аутентификации на сервер
          const response = await fetch('/project/api/auth.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              login: this.login,
              pass: this.password,
            }),
          });
  
          // Проверяем ответ сервера
          const result = await response.json();
          if (result.success) {
            // Если успешный логин, редирект на главную
            this.$router.push({ name: 'Home' });
          } else {
            alert('Неправильный логин или пароль');
          }
        } catch (error) {
          console.error('Ошибка при авторизации:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Добавьте ваши стили для страницы логина здесь */
  </style>
  