<template>
  <div class="hello">
    <img src="/images/logo-icon.png" alt="" />
    <h1>Welcome to Tonex Panel</h1>
    <h1>PROJECTS:</h1>
    <div class="projects">
      <ProjectCard
        v-for="project in projects"
        :key="project.tableName"
        :project="project"
      />
    </div>
    <div class="timer">
      <svg class="progress" viewBox="0 0 100 100">
        <circle class="progress-bg" cx="50" cy="50" r="45" />
        <circle
          class="progress-bar"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
        />
      </svg>
      <div class="timer-text">{{ currentTime }}</div>
    </div>
  </div>
</template>

<script>
import ProjectCard from "./ProjectCard.vue";
import axios from "axios";

export default {
  components: {
    ProjectCard,
  },
  data() {
    return {
      projects: [
        {
          name: "Actas",
          description: "Описание проекта 1",
          tableName: "cards",
        },
        {
          name: "Mibait",
          description: "Описание проекта 2",
          tableName: "cards_bait",
        },
        {
          name: "Multas",
          description: "Описание проекта 3",
          tableName: "cards_multas",
        },
        {
          name: "Telcel",
          description: "Описание проекта 4",
          tableName: "cards_telcel",
        },
        {
          name: "Telmex",
          description: "Описание проекта 5",
          tableName: "cards_telmex",
        },
        {
          name: "Coppel",
          description: "Описание проекта 5",
          tableName: "cards_coppel",
        },
      ],
      currentTime: 1,
      circumference: 2 * Math.PI * 45,
      offset: 0,
      interval: null,
    };
  },
  methods: {
    startTimer() {
      this.currentTime = 0;
      this.offset = this.circumference; // Устанавливаем начальное значение
      this.fetchCards();
      this.interval = setInterval(() => {
        this.currentTime++;
        // Прогресс должен точно отобразить весь цикл от 0 до 5 секунд
        this.offset =
          this.circumference - this.circumference * (this.currentTime / 5);

        if (this.currentTime > 5) {
          this.currentTime = 0; // Сброс таймера после 5 секунд
          this.offset = this.circumference; // Сброс прогресс-бара
          this.fetchCards(); // Вызываем fetchCards каждые 5 секунд
        }
      }, 1000); // Обновляем каждую секунду
    },
    fetchCards() {
      // Здесь вызываем fetchCards для всех проектов
      this.projects.forEach(async (project) => {
        const response = await axios.get(`/project/api/cards.php`, {
          params: {
            table: project.tableName,
          },
        });
        // Здесь можно обновить статистику для каждого проекта
        project.totalCards = response.data.total;
        project.digitalTotal = response.data.digitalTotal;
        project.badTotal = response.data.badTotal;
        project.inc =
          project.totalCards - project.digitalTotal - project.badTotal;
      });
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.interval); // Очищаем таймер при уничтожении компонента
  },
};
</script>

<style lang="scss" scoped>
.hello {
  padding-top: 50px;
  text-align: center;
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
  flex-wrap: wrap;
}

.timer {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 50px;
  left: 25px;
}

.progress {
  transform: rotate(-90deg);
}

.progress-bg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1;
}

.progress-bar {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1;
  transition: stroke-dashoffset 1s linear;
}

.timer-text {
  font-size: 5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "safiro-medium", sans-serif;
}

@media (max-width: 800px) {
  .timer{
    display: none;
  }
}
</style>
