import { createApp } from 'vue';
import App from './App.vue';
// import './assets/styles/main.scss'; // Если вы используете SCSS, раскомментируйте
import './assets/styles/global.css';
import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from './components/HomeComponent.vue';
import MainPanel from './components/MainPanel.vue';
import LoginComp from './components/LoginComp.vue';

// Определение маршрутов
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
    meta: { requiresAuth: true }, 
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginComp,
  },
  {
    path: '/project/:tableName', // Параметр tableName из ProjectCard
    name: 'MainPanel',
    component: MainPanel,
    props: true, // Позволяет передавать параметры как свойства
    meta: { requiresAuth: true }, 
  },
];

// Создание маршрутизатора
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!document.cookie.split('; ').find(row => row.startsWith('user_id='));
  
  // Если маршрут требует авторизации, а пользователь не авторизован
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login'); // Перенаправляем на логин
  } else {
    next(); // Иначе продолжаем
  }
});

// Создание приложения Vue
createApp(App)
  .use(router) // Подключение маршрутизатора
  .mount('#app'); // Монтирование приложения
